import { CircleInitials } from 'components/CircleInitials';
import { APIHistory } from 'hooks/queries';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type APIKeyHistoryItemProps = {
  historyEvent: APIHistory;
  module: string;
};

export const APIKeyHistoryItem: React.FC<APIKeyHistoryItemProps> = ({
  historyEvent,
  module,
}: APIKeyHistoryItemProps) => {
  const { t } = useTranslation();

  const userName =
    module === 'apiKeyGenerator'
      ? t(
          `features:data-connection:apiKeyManagement:keyHistory:${historyEvent.userName}`,
        )
      : historyEvent.userName;

  const perspective = t(
    `features:data-connection:apiKeyManagement:keyHistory:perspectives:${
      module === 'apiKeyGenerator' ? 'secondPerson' : 'thirdPerson'
    }`,
  );

  return (
    <div className="my-6 flex flex-col">
      <div className="text-grey-600 ml-5 pl-3">
        {new Date(historyEvent.dateOfActivity).toLocaleString('en-GB', {
          minute: '2-digit',
          hour: '2-digit',
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}
      </div>
      <div className="flex flex-row">
        <div>
          <CircleInitials name={historyEvent.userName} />
        </div>
        <div className="mt-1">
          <Trans
            i18nKey={`features:data-connection:apiKeyManagement:keyHistory:historyItem_${historyEvent.activity}`}
            values={{
              userName,
              perspective,
              activity: historyEvent.activity,
              projectName: historyEvent.projectName,
              apiKey: historyEvent.apiKey,
              fromUser: historyEvent.fromUser,
              toUser: historyEvent.toUser,
            }}
          />
        </div>
      </div>
      <span
        className="bg-grey-200 w-2px absolute ml-3 mt-9 h-7"
        aria-hidden="true"
      />
    </div>
  );
};
