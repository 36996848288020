import { Alert } from 'components/Alert';
import { APIGeneratorModal } from 'components/ApiKeyManagement/APIGeneratorModal';
import { APIKeyTransferModal } from 'components/ApiKeyManagement/APIKeyTransferModal';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import { PermissionDenied } from 'components/PermissionDenied/PermissionDenied';
import { routes } from 'controllers/ContentController/Routes';
import { useApiKeys, useCurrentPrincipal, useCurrentUser } from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Permission, Role } from 'shared/types/authorization';
import { Heading } from '../../../components/Heading';
import { Tab } from '../../../components/Tabs/Tab';
import { Tabs } from '../../../components/Tabs/Tabs';
import { APIKeyHistory } from './APIKeyHistory/APIKeyHistory';
import { APIKeyOverview } from './APIKeyOverview/APIKeyOverview';

export const APIKeyManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('key-overview');
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const navigate = useNavigate();
  const { dataConnectionRoute, portalMembersRoute } = routes(
    currentPrincipal.slug,
  );
  const [showAPIGeneratorModal, setShowAPIGeneratorModal] = useState(false);
  const {
    areAvailableKeysLoading,
    apiKeys,
    apiHistory,
    isApiKeyHistoryLoading,
  } = useApiKeys();
  const { userHasPermission } = usePermission();

  const isApiGeneratorRoute = useLocation()
    .pathname.toString()
    .includes('api-key-generator');

  const isDeveloperRole =
    user.roles[currentPrincipal.id] === Role.PRINCIPAL_DEVELOPER &&
    !user.global_user;

  const isAdminRole =
    user.roles[currentPrincipal.id] === Role.PRINCIPAL_ADMIN ||
    user.global_user;

  const module = isApiGeneratorRoute ? 'apiKeyGenerator' : 'apiKeyManagement';

  const showBackButton = !isDeveloperRole && !isApiGeneratorRoute;

  const onCancel = () => {
    setShowAPIGeneratorModal(false);
  };

  return userHasPermission(Permission.DATA_CONNECTION_API_MANAGEMENT) ? (
    <>
      <div className="max-w-xl-content xl:min-w-content mx-auto mb-7 space-y-5 px-4 pt-6 md:mb-9 md:px-6 md:pt-7">
        {showBackButton && (
          <div className="mb-0">
            <div className="mb-2">
              <Link
                label={t('linksOrButtons:back')}
                icon="ArrowLeft"
                iconPosition="left"
                onClick={() => navigate(dataConnectionRoute.url)}
              />
            </div>
          </div>
        )}
        {isAdminRole && (
          <div className="flex w-full justify-end">
            <div className="mb-2 lg:w-1/2">
              <Alert
                message={t(
                  `features:data-connection:apiKeyManagement:banner:message`,
                )}
                flavour="promo"
                icon="Mail"
                iconSize="small"
                iconTop
                buttonSize="small"
                hasBorder={false}
                buttonLabelFontSize="text-xs"
                buttonLabel={t(
                  `features:data-connection:apiKeyManagement:banner:buttonText`,
                )}
                onClick={() => navigate(portalMembersRoute.url)}
              />
            </div>
          </div>
        )}
        <div className="space-y-4">
          <Heading
            text={t(`features:data-connection:${module}:heading`)}
            margin={0}
            level="h1"
          />
          <div className="text-grey-700 mt-1 w-3/4 ">
            {t(`features:data-connection:${module}:description`)}
          </div>
        </div>

        <Tabs activeTab={activeTab} onTabChange={setActiveTab}>
          <Tab
            title={t(`features:data-connection:${module}:tabs:keyOverview`)}
            tabKey="key-overview"
            key="key-overview"
          >
            {isApiGeneratorRoute && (
              <div className="mb-5 flex items-center justify-between">
                <div>
                  <Heading
                    text={t(
                      `features:data-connection:${module}:keyOverview:projects`,
                    )}
                    margin={0}
                    level="h3"
                  />
                </div>
                {!!apiKeys?.length && (
                  <div>
                    <Button onClick={() => setShowAPIGeneratorModal(true)}>
                      {t(
                        `features:data-connection:${module}:keyOverview:buttonText`,
                      )}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <APIKeyOverview
              areAvailableKeysLoading={areAvailableKeysLoading}
              apiKeys={apiKeys}
              handleShowAPIGeneratorModal={() => setShowAPIGeneratorModal(true)}
              module={module}
              tab="keyOverview"
            />
          </Tab>
          <Tab
            title={t(`features:data-connection:${module}:tabs:keyHistory`)}
            tabKey="all-documents"
            key="all-documents"
          >
            <APIKeyHistory
              module={module}
              currentPrincipal={currentPrincipal.id}
              apiHistory={apiHistory}
              isApiKeyHistoryLoading={isApiKeyHistoryLoading}
            />
          </Tab>
        </Tabs>
      </div>
      {showAPIGeneratorModal && (
        <APIGeneratorModal
          onCancel={onCancel}
          onConfirmAPICopy={() => setShowAPIGeneratorModal(false)}
        />
      )}
      <APIKeyTransferModal />
    </>
  ) : (
    <PermissionDenied />
  );
};
