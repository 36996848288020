import { TileButtonWithIcon } from 'components';
import { Heading } from 'components/Heading';
import { routes } from 'controllers/ContentController/Routes';
import { useCurrentPrincipal, useCurrentUser } from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission } from 'shared/types/authorization';

export const DataConnection: React.FC = () => {
  const { user } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const { ediInformationRoute, apiKeyManagementRoute } = routes(
    currentPrincipal.slug,
  );
  const { t } = useTranslation();
  const { userHasPermission } = usePermission();

  const navigate = useNavigate();

  const navigateToPage = (link: string) => {
    navigate(link);
  };

  return (
    <div className="w-full px-4 md:px-6">
      <div className="mb-7 pt-6 md:mb-9 md:pt-7" data-test="page-header">
        <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
          <Heading
            text={t('features:data-connection:heading')}
            margin={0}
            level="h1"
          />
        </div>
      </div>

      <div className="mt-9 flex w-full justify-center">
        <Heading
          text={t('features:data-connection:startMessage')}
          margin={0}
          level="h2"
        />
      </div>

      <div className="mt-9 flex w-full flex-col items-center gap-4 md:flex-row md:items-stretch md:justify-center lg:gap-6">
        <TileButtonWithIcon
          title={t('features:data-connection:getData:tile:title')}
          description={t('features:data-connection:getData:tile:description')}
          iconName="Global"
          teaserMessage={t('features:data-connection:teaserMessage')}
        />
        <TileButtonWithIcon
          title={t('features:data-connection:apiKeyManagement:tile:title')}
          description={t(
            'features:data-connection:apiKeyManagement:tile:description',
          )}
          iconName="Activation"
          teaserMessage={
            userHasPermission(Permission.DATA_CONNECTION_API_MANAGEMENT)
              ? undefined
              : ' '
          }
          onClick={
            userHasPermission(Permission.DATA_CONNECTION_API_MANAGEMENT)
              ? () => navigateToPage(apiKeyManagementRoute.url)
              : undefined
          }
        />
        <TileButtonWithIcon
          title={t('features:data-connection:ediInfo:tile:title')}
          description={t('features:data-connection:ediInfo:tile:description')}
          iconName="EDIInfo"
          iconSubmit="ArrowRight"
          onClick={() => navigateToPage(ediInformationRoute.url)}
        />
      </div>
    </div>
  );
};
