import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApiKeyScopeOptions } from 'utils/firebase/cloud-functions';
import { getFirebaseData } from './useApiKeys';
import { useCurrentPrincipal } from './useCurrentPrincipal';
import { useCurrentUser } from './useCurrentUser';

export type Scope = {
  name: string;
  description: string;
  active: boolean;
};

export const useScopes = (): UseQueryResult<Scope[], Error> => {
  const { user: currentUser } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(currentUser.id);

  return useQuery({
    queryFn: async () =>
      await getApiKeyScopeOptions({
        bearerToken: (await getFirebaseData(currentPrincipal.id))?.bearerToken,
      }),
    queryKey: ['scopes'],
    select: ({ data }) => data,
    enabled: true,
    refetchOnWindowFocus: false,
  });
};
